var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('user-list-add-new',{attrs:{"role-options":_vm.roleOptions,"plan-options":_vm.planOptions},on:{"refetch-data":_vm.fetchUsers},model:{value:(_vm.isAddNewUserSidebarActive),callback:function ($$v) {_vm.isAddNewUserSidebarActive=$$v},expression:"isAddNewUserSidebarActive"}}),_c('v-card',[_c('v-divider',{staticClass:"mt-4"}),_c('v-card-text',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('Can',{attrs:{"I":"invite","a":"users"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();_vm.isAddNewUserSidebarActive = !_vm.isAddNewUserSidebarActive}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))]),_c('span',[_vm._v(" Add New User ")])],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.userListTable,"options":_vm.options,"server-items-length":_vm.totalUserListTable,"loading":_vm.loading,"show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.fullName",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{class:item.avatar ? '' : 'v-avatar-light-bg primary--text',attrs:{"color":item.avatar ? '' : 'primary',"size":"32"}},[(item.avatar)?_c('v-img',{attrs:{"src":item.avatar}}):_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(item.fullName)))])],1),_c('div',{staticClass:"d-flex flex-column ms-3"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none",attrs:{"to":{ name : 'apps-user-view', params : { id : item.id } }}},[_vm._v(" "+_vm._s(item.fullName)+" ")]),_c('small',[_vm._v("@"+_vm._s(item.username))])],1)],1)]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, onMenu, onTooltip)),[_c('v-avatar',{class:("v-avatar-light-bg " + (_vm.resolveUserRoleVariant(item.role)) + "--text"),attrs:{"size":"30","color":_vm.resolveUserRoleVariant(item.role)}},[_c('v-icon',{attrs:{"size":"18","color":_vm.resolveUserRoleVariant(item.role)}},[_vm._v(" "+_vm._s(_vm.resolveUserRoleIcon(item.role))+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.role))])])]}}],null,true)},[_c('Can',{attrs:{"I":"update","a":"roles"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-subheader',[_vm._v("Change role")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.resolveChangeRole(item.id, 'admin')}}},[_c('v-list-item-title',[_c('span',[_vm._v("Admin")])])],1)],1),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.resolveChangeRole(item.id, 'manager')}}},[_c('v-list-item-title',[_c('span',[_vm._v("Content manager")])])],1)],1),_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.resolveChangeRole(item.id, 'viewer')}}},[_c('v-list-item-title',[_c('span',[_vm._v("Viewer")])])],1)],1)],1)],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg font-weight-semibold text-capitalize",class:((_vm.resolveUserStatusVariant(item.status)) + "--text"),attrs:{"small":"","color":_vm.resolveUserStatusVariant(item.status)}},[_vm._v(" "+_vm._s(item.status)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"to":{name:'apps-user-view',params:{id:item.id}}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiFileDocumentOutline)+" ")]),_c('span',[_vm._v("Details")])],1)],1),_c('Can',{attrs:{"I":"delete","a":"users"}},[_c('v-list-item',{on:{"click":function($event){return _vm.deleteUserClicked(item.id)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }