<template>
  <v-navigation-drawer
    :value="isAddNewUserSidebarActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 350 : '100%'"
    app
    @input="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center mb-4">
        <span class="font-weight-semibold text-base text--primary">Add New User</span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-add-new-user-sidebar-active',false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form
          ref="form"
          v-model="valid"
          @submit.prevent="addButtonClicked"
        >
          <!--<v-text-field
            v-model="userData.fullName"
            outlined
            dense
            :rules="[validators.required]"
            label="Full Name"
            placeholder="John Doe"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>-->

          <!--<v-text-field
            v-model="userData.username"
            :rules="[validators.required]"
            outlined
            dense
            label="Username"
            placeholder="Username"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>-->

          <v-text-field
            v-model="userData.email"
            :rules="[validators.required,validators.emailValidator]"
            outlined
            dense
            type="email"
            label="Email"
            placeholder="Email"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>

          <!--<v-text-field
            v-model="userData.contact"
            :rules="[validators.required]"
            outlined
            dense
            type="number"
            label="Contact"
            placeholder="Contact"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>-->

          <!--<v-text-field
            v-model="userData.company"
            :rules="[validators.required]"
            outlined
            dense
            label="Company"
            placeholder="Company"
            hide-details="auto"
            class="mb-6"
          ></v-text-field>-->

          <!--<v-select
            v-model="userData.country"
            :rules="[validators.required]"
            label="Country"
            :items="countries"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>-->

          <v-select
            v-model="userData.role"
            :rules="[validators.required]"
            label="User Role"
            :items="roleOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            v-on:input="ChangeTooltip(`${userData.role}`)"
          >
          </v-select>

          <p>{{ tooltipText.text }}</p>

          <!--<v-select
            v-model="userData.currentPlan"
            :rules="[validators.required]"
            label="Plan"
            :items="planOptions"
            item-text="title"
            item-value="value"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
          >
          </v-select>-->
          <div :class="this.colord">{{this.msgd }}</div>
          <v-btn
            color="primary"
            class="me-3"
            type="submit"
          >
            Add
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            type="reset"
            @click="cancelButtonCLicked"
          >
            Close
          </v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mdiClose } from '@mdi/js'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'


export default {
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
    },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: "admin",
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }

    const valid = ref(false)
    const form = ref(null)

    const validate = () => {
      form.value.validate()
    }

    const resetForm = () => {
      form.value.reset()
    }

    const tooltipText = { text: "Admins have full access to all viewers and all features", show: false}

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

    const ChangeTooltip = (data) => {
      switch (data) {
        case "admin":
          tooltipText.text = "Admins have full access to all viewers and all features"
          break;
        case "manager":
          tooltipText.text = "Content Managers have full access to all viewers, but cannot invite to og modify users"
          break;
        case "viewer":
          tooltipText.text = "Viewers only have have access to Desktop viewer"
          break;
        default:
      }
    }

    const cancelButtonClicked = () => {
      window.location.reload();
    }

    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
      resetForm()
      emit('update:is-add-new-user-sidebar-active', false)
    }

    var color = ""
    var msg = ""

    const onSubmit = async () => {
      return new Promise(resolve => {
        if (valid.value) {
          store.dispatch('app-user/addUser', userData.value).then((promise) => {
            msg = promise.text
            if (promise.error) {
              color = "error--text"
            }
            else {
              color = "success--text"
            }
            resolve(
              {
                color: color,
                msg: msg
              })
          })
          //resetuserData()
        } else {
          validate()
          msg = ""
          color = ""
          resolve(
            {
              color: color,
              msg: msg
            })
        }
      })
     }


    return {
      cancelButtonClicked,
      resetuserData,
      form,
      countries,
      userData,
      tooltipText,
      ChangeTooltip,
      valid,
      validate,
      onSubmit,
      msg,
      color,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
      },
    }
    },



    methods: {
      async cancelButtonCLicked() {
          this.resetuserData()
          this.msgd = ""
      },
      async addButtonClicked()
      {
        (async () => {
          var promise = await this.onSubmit()
          this.colord = promise.color
          this.msgd = promise.msg
        })();
      }
    },
    data() {
      return {
        msgd: "",
        colord: "",
        showmsgd : false,
      }
    },


}
</script>
